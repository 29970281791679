import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import ProfileGrid from "../components/ProfileGrid/ProfileGrid"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { removeContactsWithNoImages } from "../scripts/helpers"
import { Helmet } from 'react-helmet'


const contactInSubcategory = (contact, subcategory) => {
  if (!contact || !subcategory) {
    return false
  }

  const modelSubcategories = contact.node.modelSubcategories
  const talentSubcategories = contact.node.talentSubcategories

  if (modelSubcategories) {
    if (modelSubcategories.toLowerCase().includes(subcategory)) {
      return true
    }
  }
  if (talentSubcategories) {
    if (talentSubcategories.toLowerCase().includes(subcategory)) {
      return true
    }
  }
}

//Filter the data and return only data that matches that sub-category
const filterSubcategoryData = (categoryContactsData, subcategory) => {

  const subcategoryContactsData = categoryContactsData.filter(
    contact => contactInSubcategory(contact, subcategory)
  )

  return subcategoryContactsData
}


export default function Category({ data, pageContext }) {
  const category = pageContext
  const categoryData = data

  //Check for sub-category
  let queryString = ""
  let subcategory = ""
  if (typeof window !== `undefined`){ 
    queryString = new URLSearchParams(window.location.search);
    subcategory = queryString.get('cat')
  }

  
  let categoryContactsData = categoryData.allContacts.edges

  // Filter by model OR talent subcategories
  if (subcategory) {
    categoryContactsData = filterSubcategoryData(categoryContactsData, subcategory)
  }

  // const allImagesData = categoryData.images.nodes
  // const imagesData = keepOnlyFirstImageFromEachContact(allImagesData)
  const imagesData = pageContext.tileImagesData

  // Filter to remove contacts with no images
  categoryContactsData = removeContactsWithNoImages(categoryContactsData, imagesData)

  const bannerImage = getImage(category.bannerImage.gatsbyImageData)

  return (
    <Layout>
      <Helmet>
        <title>Monarch {category.categoryName}</title>
        <meta name="description" content={`Monarch ${category.categoryName} category page`} />
      </Helmet>

      <title>Monarch {category.categoryName}</title>

      <div className="banner-container">
        <div className="banner-text">
          <h1 className="banner-text-title">{category.categoryName}</h1>
        </div>
        <GatsbyImage image={bannerImage} alt={category.categoryName + 'banner image'} className="banner-image-container"
           imgClassName="banner-image" height="400px" objectFit="cover"/>
      </div>
      
      <ProfileGrid contactsData={categoryContactsData} imagesData={imagesData} />

    </Layout>
  )
}

export const query = graphql`
  query($categoryRegex: String!) {
    allContacts (
      filter: {
        categories: {
          regex: $categoryRegex
        },
        monarchStatus: {
          eq: "Active"
        }
      }
      sort: { 
        order: [ASC, ASC],
        fields: [sortNumber, firstname]
      }
    )
  {
    edges {
      node {
        id
        firstname
        lastname
        fullname
        dateOfBirth
        genders
        sortNumber
        categories
        modelSubcategories
        talentSubcategories
        experience {
          training
        }
        skills {
          accentSkills
          artisticSkills
          athleteSkills
          circusSkills
          combatAndStuntsSkills
          danceSkills
          drivingSkills
          flyingSkills
          horseSkills
          languages
          miscSkillsAttributes
          musicAndSingingSkills
          otherSkills
          otherSportsSkills
          voiceoverMcPresentingSkills
          waterSportsSkills
          winterSportsSkills
        }
        info {
          city
          ethnicity
          closestCentre
          eyeColour
          hairColour
          nationality
          nativeLanguage
          naturalAccent
          adminNotes
        }
        fields {
          slug
        }
      }
      }
    }
  }
`

// export const query = graphql`
//   query($categoryRegex: String!) {
//     allContacts (
//       filter: {
//         categories: {
//           regex: $categoryRegex
//         },
//         monarchStatus: {
//           eq: "Active"
//         }
//       }
//       sort: { 
//         order: [ASC, ASC],
//         fields: [sortNumber, firstname]
//       }
//     )
//   {
//     edges {
//       node {
//         id
//         firstname
//         lastname
//         fullname
//         dateOfBirth
//         genders
//         sortNumber
//         categories
//         modelSubcategories
//         talentSubcategories
//         experience {
//           training
//         }
//         skills {
//           accentSkills
//           artisticSkills
//           athleteSkills
//           circusSkills
//           combatAndStuntsSkills
//           danceSkills
//           drivingSkills
//           flyingSkills
//           horseSkills
//           languages
//           miscSkillsAttributes
//           musicAndSingingSkills
//           otherSkills
//           otherSportsSkills
//           voiceoverMcPresentingSkills
//           waterSportsSkills
//           winterSportsSkills
//         }
//         info {
//           city
//           ethnicity
//           closestCentre
//           eyeColour
//           hairColour
//           nationality
//           nativeLanguage
//           naturalAccent
//         }
//         fields {
//           slug
//         }
//       }
//       }
//     }
//     images: allS3Object {
//       nodes {
//         Key
//         localFile {
//           childImageSharp {
//             gatsbyImageData(width: 300)
//           }
//         }
//       }
//     }
//   }
// `